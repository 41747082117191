import * as React from 'react'
import { IconSvgProps } from './types'

const File = ({ size = 14, ...props }: IconSvgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 15 15'
    fill='none'
    {...props}
    width={size}
    height={size}
  >
    <path
      d='M8.74263 1.85189V4.26137C8.74263 4.58807 8.74263 4.75142 8.80621 4.8762C8.86214 4.98596 8.95138 5.0752 9.06114 5.13113C9.18592 5.19471 9.34927 5.19471 9.67597 5.19471H12.0854M9.9093 8.11133H5.24264M9.9093 10.4447H5.24264M6.4093 5.778H5.24264M8.74263 1.69466H5.7093C4.72921 1.69466 4.23916 1.69466 3.86482 1.8854C3.53553 2.05318 3.26782 2.3209 3.10004 2.65018C2.9093 3.02453 2.9093 3.51457 2.9093 4.49466V10.5613C2.9093 11.5414 2.9093 12.0315 3.10004 12.4058C3.26782 12.7351 3.53553 13.0028 3.86482 13.1706C4.23916 13.3613 4.72921 13.3613 5.7093 13.3613H9.44263C10.4227 13.3613 10.9128 13.3613 11.2871 13.1706C11.6164 13.0028 11.8841 12.7351 12.0519 12.4058C12.2426 12.0315 12.2426 11.5414 12.2426 10.5613V5.19466L8.74263 1.69466Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default File
