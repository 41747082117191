import * as React from 'react'
import { IconSvgProps } from './types'

const CoinsSwap = ({ size = 14, ...props }: IconSvgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 15 15'
    fill='none'
    {...props}
    width={size}
    height={size}
  >
    <g clipPath='url(#clip0_1626_6726)'>
      <path
        d='M4.07589 3.732L5.24255 2.56533M5.24255 2.56533L4.07589 1.39867M5.24255 2.56533H4.07589C2.78722 2.56533 1.74255 3.61 1.74255 4.89867M8.40257 8.05868C8.85889 8.27664 9.3698 8.39867 9.90922 8.39867C11.8422 8.39867 13.4092 6.83166 13.4092 4.89867C13.4092 2.96567 11.8422 1.39867 9.90922 1.39867C7.97622 1.39867 6.40922 2.96567 6.40922 4.89867C6.40922 5.43808 6.53125 5.949 6.7492 6.40532M8.74255 9.56533C8.74255 11.4983 7.17555 13.0653 5.24255 13.0653C3.30956 13.0653 1.74255 11.4983 1.74255 9.56533C1.74255 7.63234 3.30956 6.06533 5.24255 6.06533C7.17555 6.06533 8.74255 7.63234 8.74255 9.56533Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1626_6726'>
        <rect
          width='14'
          height='14'
          fill='currentColor'
          transform='translate(0.575928 0.231998)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default CoinsSwap
