import { LayerProvider } from '@layerfi/components'
import React, { useEffect, useState } from 'react'
import { useParams, Outlet, Navigate } from 'react-router-dom'
import { Sidebar } from './components/Sidebar'
import { FourOhFour } from './pages/404'
import { Loading } from './pages/Loading'
import { CreateBusiness } from './pages/CreateBusiness'
import { ImportTransactionData } from './pages/dataImportFlow/ImportTransactionData'
import { useSidebarContext } from './contexts/SidebarContext'
import { useAuth } from './contexts/AuthContext'
import { DateRangeDatePickerModes } from './pages/Admin'

export interface Demo {
  id: string
  clientName: string
  brandColorHex: string
  brandColorLightHex: string
  brandColorDarkHex: string
  brandColorTextHex: string
  brandLogoUrl: string
  businessId?: string
  datePickerMode?: string
  hasImportedRevenueData?: boolean
  bookkeepingOn?: boolean
  showDescriptions?: boolean
  showReceiptUploads?: boolean
  showInvoicesTab?: boolean
  useComparisonPnl?: boolean
  showProjectView?: boolean
}

export const LAYER_API_URL =
  process.env.REACT_APP_LAYER_API_URL_OVERRIDE ?? 'https://sandbox.layerfi.com'

export const LAYER_SCOPE =
  process.env.REACT_APP_LAYER_SCOPE_OVERRIDE ??
  'https://sandbox.layerfi.com/sandbox'

export const DEFAULT_ACCOUNT_NAME = 'Layer Demo Checking'

export const Root = () => {
  const { demoName } = useParams()
  const { isSidebarOpen } = useSidebarContext()

  const { getClientName, getIsAdmin } = useAuth()
  const [demo, setDemo] = useState<Demo | null>(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [importingTransactions, setImportingTransactions] = useState(false)
  const [isMobileView, setIsMobileView] = useState(false)
  const [showTooltips, setShowTooltips] = useState(false)
  const [isBookkeepingMode, setBookkeepingMode] = useState(false)
  const [bearerToken, setBearerToken] = useState(null)
  const [showDescriptions, setShowDescriptions] = useState(true)
  const [showReceiptUploads, setShowReceiptUploads] = useState(true)
  const [showInvoicesTab, setShowInvoicesTab] = useState(false)
  const [datePickerMode, setDatePickerMode] =
    useState<DateRangeDatePickerModes | null>(null)
  const [useComparisonPnl, setUseComparisonPnl] = useState(false)
  const [showProjectView, setShowProjectView] = useState(false)

  if (!getIsAdmin() && getClientName() !== demoName) {
    return <Navigate to='/login' replace />
  }

  useEffect(() => {
    if (demoName) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/api/demos/${demoName}`)
          if (!response.ok) {
            if (response.status === 404) {
              setNotFound(true)
            } else {
              throw new Error('Fetch failed')
            }
          } else {
            const data = await response.json()
            const demoData: Demo = {
              id: data.id,
              clientName: data.client_name,
              brandColorHex: data.brand_color_hex,
              brandColorLightHex: data.brand_color_light_hex,
              brandColorDarkHex: data.brand_color_dark_hex,
              brandColorTextHex: data.brand_color_text_hex,
              brandLogoUrl: data.brand_logo_url,
              businessId: data.business_id,
              datePickerMode: data.date_picker_mode,
              hasImportedRevenueData: data.has_imported_revenue_data,
              bookkeepingOn: data.bookkeeping_on,
              showDescriptions: data.show_descriptions,
              showReceiptUploads: data.show_receipt_uploads,
              showInvoicesTab: data.show_invoices_tab,
              useComparisonPnl: data.use_comparison_pnl,
              showProjectView: data.show_project_view,
            }
            setDemo(demoData)
            setBookkeepingMode(data.bookkeeping_on ?? false)
            setShowDescriptions(data.show_descriptions ?? true)
            setShowReceiptUploads(data.show_receipt_uploads ?? true)
            setShowInvoicesTab(data.show_invoices_tab ?? false)
            setUseComparisonPnl(data.use_comparison_pnl ?? false)
            setShowProjectView(data.show_project_view ?? false)
            setDatePickerMode(data.date_picker_mode)
            const url = 'https://auth.layerfi.com/oauth2/token'
            const clientId = process.env.REACT_APP_CLIENT_ID
            const clientSecret = process.env.REACT_APP_CLIENT_SECRET
            const credentials = btoa(`${clientId}:${clientSecret}`)

            const headers = new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Basic ${credentials}`,
            })

            const body = new URLSearchParams()
            body.append('grant_type', 'client_credentials')
            body.append('scope', LAYER_SCOPE)
            body.append('client_id', clientId ?? '')

            const tokenResponse = await fetch(url, {
              method: 'POST',
              headers: headers,
              body: body,
            })

            if (!tokenResponse.ok) throw new Error('Failed to fetch token')

            const tokenData = await tokenResponse.json()
            setBearerToken(tokenData.access_token)
            if (demoData.businessId && bearerToken) {
              fetch(
                `${LAYER_API_URL}/v1/businesses/${demoData.businessId}/sync`,
                {
                  method: 'POST',
                  headers: {
                    Authorization: `Bearer ${tokenData.access_token}`,
                    'Content-Type': 'application/json',
                  },
                },
              )
            }
          }
        } catch (error) {
          console.error('Fetch Error:', error)
          setNotFound(true)
        } finally {
          setLoading(false)
        }
      }

      fetchData()
    }
  }, [demoName])

  if (loading) {
    return <Loading />
  }

  if (!demo || notFound || !demoName) {
    return <FourOhFour />
  }

  if (loading || !bearerToken) {
    return <Loading />
  }

  if (!demo.businessId) {
    return <CreateBusiness demoName={demoName} bearerToken={bearerToken} />
  }

  if (importingTransactions) {
    return (
      <ImportTransactionData
        demoName={demoName}
        businessId={demo.businessId}
        bearerToken={bearerToken}
        setImportingTransactions={setImportingTransactions}
      />
    )
  }
  return (
    <LayerProvider
      businessId={demo.businessId}
      environment={process.env.REACT_APP_ENVIRONMENT ?? ''}
      appId={process.env.REACT_APP_APP_ID ?? ''}
      appSecret={process.env.REACT_APP_APP_SECRET ?? ''}
      theme={{
        colors: {
          light: { hex: demo.brandColorLightHex },
          dark: { hex: demo.brandColorDarkHex },
          text: { hex: demo.brandColorTextHex },
        },
      }}
      usePlaidSandbox={!(process.env.REACT_APP_USE_PLAID_SANDBOX == 'false')}
    >
      <div
        className={`structure sidebar--${isSidebarOpen ? 'open' : 'closed'}`}
      >
        <Sidebar
          brandLogoUrl={demo.brandLogoUrl}
          demoName={demoName}
          setImportingTransactions={setImportingTransactions}
          isMobileView={isMobileView}
          setIsMobileView={setIsMobileView}
          isBookkeepingMode={isBookkeepingMode}
          setBookkeepingMode={setBookkeepingMode}
          showInvoicesTab={showInvoicesTab}
          showTooltips={showTooltips}
          setShowTooltips={setShowTooltips}
        />
        <section className='main-content'>
          <Outlet
            context={{
              bearerToken: bearerToken,
              businessId: demo.businessId,
              datePickerMode: datePickerMode,
              isMobileView: isMobileView,
              demoName: demo.clientName,
              isBookkeepingMode: isBookkeepingMode,
              showDescriptions: showDescriptions,
              showReceiptUploads: showReceiptUploads,
              showInvoicesTab: showInvoicesTab,
              useComparisonPnl: useComparisonPnl,
              showTooltips: showTooltips,
              showProjectView: showProjectView,
            }}
          />
        </section>
      </div>
    </LayerProvider>
  )
}
