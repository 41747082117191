import React, { useEffect, useState } from 'react'
import { Navigate, useOutletContext } from 'react-router-dom'
import { Loading } from './Loading'
import { LAYER_API_URL } from '../Root'

interface LineItem {
  account_identifier: string | null
  description: string
  product: string
  unit_price: number
  quantity: string
  subtotal: number
  discount_amount: number
  sales_taxes_total: number
  total_amount: number
}

interface PaymentAllocation {
  payment_id: string
  amount: number
  transaction_tags: string[]
}

interface Invoice {
  id: string
  status: string
  sent_at: string
  due_at: string | null
  paid_at: string | null
  voided_at: string | null
  invoice_number: string
  recipient_name: string
  line_items: LineItem[]
  subtotal: number
  additional_discount: number
  additional_sales_taxes_total: number
  tips: number
  total_amount: number
  outstanding_balance: number
  payment_allocations: PaymentAllocation[]
  imported_at: string
  updated_at: string | null
}

const formatCurrency = (amount: number) => (amount / 100).toFixed(2)

export const Invoices = () => {
  const { bearerToken, businessId, showInvoicesTab } = useOutletContext<{
    bearerToken: string
    businessId: string
    showInvoicesTab: boolean
  }>()
  const [invoices, setInvoices] = useState<Invoice[] | null>(null)

  if (!showInvoicesTab) {
    return <Navigate to='../' replace />
  }

  useEffect(() => {
    const fetchData = async () => {
      const getInvoicesResponse = await fetch(
        `${LAYER_API_URL}/v1/businesses/${businessId}/invoices`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        },
      )
      const body = await getInvoicesResponse.json()
      const data = body.data as Invoice[] | null
      const sortedData = data
        ? data.sort((a, b) => {
            const dateA = new Date(a.sent_at)
            const dateB = new Date(b.sent_at)
            return dateB.getTime() - dateA.getTime()
          })
        : null
      setInvoices(sortedData)
    }
    fetchData()
  }, [])

  return invoices ? (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'left' }}>
          <th style={{ border: '1px solid #ddd', padding: '8px' }}>
            Invoice #
          </th>
          <th style={{ border: '1px solid #ddd', padding: '8px' }}>
            Recipient
          </th>
          <th style={{ border: '1px solid #ddd', padding: '8px' }}>Status</th>
          <th style={{ border: '1px solid #ddd', padding: '8px' }}>Sent At</th>
          <th style={{ border: '1px solid #ddd', padding: '8px' }}>Due At</th>
          <th style={{ border: '1px solid #ddd', padding: '8px' }}>
            Total Amount
          </th>
          <th style={{ border: '1px solid #ddd', padding: '8px' }}>Details</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map(invoice => (
          <InvoiceRow key={invoice.id} invoice={invoice} />
        ))}
      </tbody>
    </table>
  ) : (
    <Loading />
  )
}

const InvoiceRow = ({ invoice }: { invoice: Invoice }) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <>
      <tr>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
          {invoice.invoice_number}
        </td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
          {invoice.recipient_name}
        </td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
          {invoice.status}
        </td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
          {new Date(invoice.sent_at).toLocaleDateString()}
        </td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
          {invoice.due_at
            ? new Date(invoice.due_at).toLocaleDateString()
            : null}
        </td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
          ${formatCurrency(invoice.total_amount)}
        </td>
        <td
          style={{
            border: '1px solid #ddd',
            padding: '8px',
            cursor: 'pointer',
          }}
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? 'Hide Details ▼' : 'Show Details ▶'}
        </td>
      </tr>
      {showDetails && (
        <tr>
          <td colSpan={7} style={{ border: '1px solid #ddd', padding: '8px' }}>
            <Details invoice={invoice} />
          </td>
        </tr>
      )}
    </>
  )
}

const Details = ({ invoice }: { invoice: Invoice }) => {
  return (
    <div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'left' }}>
            <th>Line Item Description</th>
            <th>Product</th>
            <th>Unit Price</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {invoice.line_items.map(item => (
            <tr key={item.description}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.description}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.product}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                ${formatCurrency(item.unit_price)}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.quantity}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                ${formatCurrency(item.total_amount)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'left' }}>
            <th>Payment ID</th>
            <th>Allocated Amount</th>
          </tr>
        </thead>
        <tbody>
          {invoice.payment_allocations.map(allocation => (
            <tr key={allocation.payment_id}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {allocation.payment_id}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                ${formatCurrency(allocation.amount)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
