import React, { Dispatch, SetStateAction, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

interface DemoControlsProps {
  demoName: string
  setImportingTransactions: Dispatch<SetStateAction<boolean>>
  isMobileView: boolean
  setIsMobileView: Dispatch<SetStateAction<boolean>>
  isBookkeepingMode: boolean
  setBookkeepingMode: Dispatch<SetStateAction<boolean>>
  showTooltips: boolean
  setShowTooltips: Dispatch<SetStateAction<boolean>>
}

export const DemoControls = ({
  demoName,
  setImportingTransactions,
  isMobileView,
  setIsMobileView,
  isBookkeepingMode,
  setBookkeepingMode,
  showTooltips,
  setShowTooltips,
}: DemoControlsProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const navigate = useNavigate()
  const auth = useAuth()

  return (
    <div
      className='sidebar-dev-menu'
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        padding: '5px',
        border: '1px solid gray',
        borderRadius: '10px',
        maxWidth: 125,
      }}
    >
      {isExpanded && (
        <>
          {auth.getIsAdmin() && (
            <button
              className='admin-button'
              onClick={async () => {
                console.log(`resetting ${demoName}`)
                const updateResponse = await fetch(`/api/demos/${demoName}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    clientName: demoName,
                    businessId: null,
                  }),
                })
                if (updateResponse.ok) {
                  console.log(`Successfully reset ${demoName} demo`)
                  alert(`Successfully reset ${demoName} demo`)
                } else {
                  console.error(updateResponse)
                  alert(`Failed to reset ${demoName} demo`)
                }
              }}
            >
              Reset Demo
            </button>
          )}
          <button
            className='admin-button'
            onClick={() => {
              auth.logout()
              navigate('/')
            }}
          >
            Log out
          </button>
          {auth.getIsAdmin() && (
            <button
              className='admin-button'
              onClick={() => {
                setImportingTransactions(true)
              }}
            >
              Import data
            </button>
          )}
          <button
            className='admin-button'
            onClick={() => {
              setIsMobileView(!isMobileView)
            }}
          >
            {isMobileView ? 'Mobile view' : 'Desktop view'}
          </button>
          {auth.getIsAdmin() && (
            <button
              className='admin-button'
              onClick={() => {
                setBookkeepingMode(!isBookkeepingMode)
              }}
            >
              {isBookkeepingMode ? 'Bookkeeping Off' : 'Bookkeeping On'}
            </button>
          )}
          {auth.getIsAdmin() && (
            <button
              className='admin-button'
              onClick={() => {
                setShowTooltips(!showTooltips)
              }}
            >
              {showTooltips ? 'Hide Tooltips' : 'Show Tooltips'}
            </button>
          )}
        </>
      )}
      <p
        style={{ cursor: 'pointer', margin: 0, textAlign: 'center' }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        Demo controls
      </p>
    </div>
  )
}
