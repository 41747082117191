import React from 'react'
import { ChartOfAccounts, GeneralLedgerView } from '@layerfi/components'
import { AccountingLayout } from '../../../layouts/AccountingLayout'

export const Ledger = () => {
  return (
    <AccountingLayout>
      <GeneralLedgerView />
    </AccountingLayout>
  )
}
