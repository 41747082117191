import { AccountingOverview as AccountingOverviewView } from '@layerfi/components'
import { AccountingLayout } from '../../../layouts/AccountingLayout'
import { useNavigate } from 'react-router-dom'

import './styles.css'

export const AccountingOverview = () => {
  const navigate = useNavigate()
  return (
    <AccountingLayout>
      <AccountingOverviewView
        onTransactionsToReviewClick={() => {
          navigate('../bank-transactions')
        }}
      />
    </AccountingLayout>
  )
}
