import React from 'react'
import { AccountingOverview } from '../pages/accounting/overview'
import { BookkeepingOverview } from '@layerfi/components'
import { AccountingLayout } from '../layouts/AccountingLayout'
import { useOutletContext } from 'react-router-dom'

export const LandingPage = () => {
  const { isBookkeepingMode } = useOutletContext<{
    isBookkeepingMode: boolean
  }>()

  return (
    <>
      {isBookkeepingMode ? (
        <AccountingLayout>
          <BookkeepingOverview />
        </AccountingLayout>
      ) : (
        <AccountingOverview />
      )}
    </>
  )
}
