import {
  getInvoices,
  validateInvoices,
  getOrCreateDefaultCustomAccount,
  createMatchingCustomTransactionsFromInvoices,
  createCustomTransactions,
  CustomAccount,
  validateCustomTransactions,
  getExpenseCustomTransactions,
} from './importTransactionsHelpers'

import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

import { Loading } from '../Loading'
import { LAYER_API_URL } from '../../Root'

const textAreaStyle: CSSProperties = {
  width: '100%',
  height: '200px',
  resize: 'none',
  overflowY: 'auto',
}

interface ImportTransactionDataProps {
  demoName: string
  businessId: string
  bearerToken: string
  setImportingTransactions: Dispatch<SetStateAction<boolean>>
}

interface ImportInvoicesProps {
  demoName: string
  businessId: string
  bearerToken: string
  invoicePayload: string
  setInvoicePayload: React.Dispatch<React.SetStateAction<string>>
  defaultCustomAccount: CustomAccount
}

const ImportInvoices = ({
  demoName,
  businessId,
  bearerToken,
  invoicePayload,
  setInvoicePayload,
  defaultCustomAccount,
}: ImportInvoicesProps) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const parsedInvoicePayload = JSON.parse(invoicePayload)
      if (!validateInvoices(parsedInvoicePayload)) {
        throw new Error('Invalid invoice payload structure')
      }

      const invoiceResponse = await fetch(
        `${LAYER_API_URL}/v1/businesses/${businessId}/invoices/bulk`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(parsedInvoicePayload),
        },
      )
      const invoiceResponseData = await invoiceResponse.json()
      if (!invoiceResponse.ok) throw new Error('Invoice API call failed')

      const matchingTxns = await createMatchingCustomTransactionsFromInvoices(
        parsedInvoicePayload,
        demoName,
      )
      const txnsResponse = await createCustomTransactions(
        businessId,
        bearerToken,
        defaultCustomAccount.id,
        matchingTxns,
      )

      alert('Invoices imported successfully!')
    } catch (error) {
      console.error('Error in payload or submission:', error)
      alert(`Failed to import invoices: ${error}`)
    }
  }
  return (
    <fieldset
      style={{
        minWidth: '400px',
        maxWidth: '600px',
        padding: '20px',
        border: '2px solid black',
        borderRadius: '5px',
        flex: '1',
      }}
    >
      <legend style={{ fontSize: '1.2em', padding: '0 10px' }}>
        Import Invoices
      </legend>
      <form onSubmit={handleSubmit}>
        <textarea
          style={textAreaStyle}
          value={invoicePayload}
          onChange={e => setInvoicePayload(e.target.value)}
        />
        <div style={{ textAlign: 'right' }}>
          <br />
          <button className='admin-button' type='submit'>
            Submit Invoices
          </button>
        </div>
      </form>
    </fieldset>
  )
}

interface ImportBankTransactionsProps {
  businessId: string
  bearerToken: string
  expensePayload: string
  setExpensePayload: React.Dispatch<React.SetStateAction<string>>
  defaultCustomAccount: CustomAccount
}

const ImportBankTransactions = ({
  businessId,
  bearerToken,
  expensePayload,
  setExpensePayload,
  defaultCustomAccount,
}: ImportBankTransactionsProps) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const parsedPayload = JSON.parse(expensePayload)
      if (!validateCustomTransactions(parsedPayload)) {
        throw new Error('Invalid transaction payload structure')
      }

      const responseData = await createCustomTransactions(
        businessId,
        bearerToken,
        defaultCustomAccount.id,
        parsedPayload,
      )
      alert('Bank transactions imported successfully!')
    } catch (error) {
      console.error('Error in payload or submission:', error)
      alert(`Failed to submit: ${error}`)
    }
  }

  return (
    <fieldset
      style={{
        minWidth: '400px',
        maxWidth: '600px',
        padding: '20px',
        border: '2px solid black',
        borderRadius: '5px',
        flex: '1',
      }}
    >
      <legend style={{ fontSize: '1.2em', padding: '0 10px' }}>
        Import Bank Transactions
      </legend>
      <form onSubmit={handleSubmit}>
        <textarea
          style={textAreaStyle}
          value={expensePayload}
          onChange={e => setExpensePayload(e.target.value)}
        />
        <div style={{ textAlign: 'right' }}>
          <br />
          <button className='admin-button' type='submit'>
            Submit Transactions
          </button>
        </div>
      </form>
    </fieldset>
  )
}

export const ImportTransactionData = ({
  demoName,
  businessId,
  bearerToken,
  setImportingTransactions,
}: ImportTransactionDataProps) => {
  const [loading, setLoading] = useState(true)
  const [defaultCustomAccount, setDefaultCustomAccount] =
    useState<CustomAccount | null>(null)
  const [invoicePayload, setInvoicePayload] = useState<string>('')
  const [expensePayload, setExpensePayload] = useState<string>('')

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    if (defaultCustomAccount != null) {
      const initialInvoices = getInvoices(demoName)
      setInvoicePayload(JSON.stringify(initialInvoices, null, 2))

      const initialExpenses = getExpenseCustomTransactions(demoName)
      setExpensePayload(JSON.stringify(initialExpenses, null, 2))
    }
  }, [demoName, defaultCustomAccount])

  const OtherActions = () => {
    const ApproveMatchesButton = () => {
      const handleSubmit = async () => {
        const today = new Date()
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1,
        )
        const sevenDaysAgo = new Date(today)
        sevenDaysAgo.setDate(today.getDate() - 7)
        const categorizationCutoffDate = (
          firstDayOfMonth < sevenDaysAgo ? firstDayOfMonth : sevenDaysAgo
        ).toISOString()

        try {
          const txnsLayerReviewResponse = await fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/bank-transactions?categorization_status=LAYER_REVIEW&end_date=${categorizationCutoffDate}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
            },
          )
          const txnsLayerReviewData = await txnsLayerReviewResponse.json()
          const txnsReadyForInputResponse = await fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/bank-transactions?categorization_status=READY_FOR_INPUT&end_date=${categorizationCutoffDate}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
            },
          )
          const txnsReadyForInputData = await txnsReadyForInputResponse.json()

          const transactionLayerReviewMatchPairs = txnsLayerReviewData.data
            .filter((txn: any) => (txn.suggested_matches as any[]).length > 0)
            .map((txn: any) => ({
              transaction_id: txn.id,
              suggested_match_id: txn.suggested_matches[0].id,
            }))

          const transactionReadyForInputMatchPairs = txnsReadyForInputData.data
            .filter((txn: any) => (txn.suggested_matches as any[]).length > 0)
            .map((txn: any) => ({
              transaction_id: txn.id,
              suggested_match_id: txn.suggested_matches[0].id,
            }))

          const bulkMatchLayerReviewResponse = await fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/bank-transactions/bulk-match`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                match_pairs: transactionLayerReviewMatchPairs,
              }),
            },
          )

          const bulkMatchReadyForInputResponse = await fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/bank-transactions/bulk-match`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                match_pairs: transactionReadyForInputMatchPairs,
              }),
            },
          )
          bulkMatchLayerReviewResponse.json()
          bulkMatchReadyForInputResponse.json()

          alert('Transaction matches matched approved successfully!')
        } catch (error) {
          console.error('Error approving transactions:', error)
          alert(`Failed to approve transactions: ${error}`)
        }
      }

      return (
        <button className='admin-button' onClick={handleSubmit}>
          Approve all matched transactions
        </button>
      )
    }

    const SyncBusinessButton = () => {
      const handleSubmit = () => {
        try {
          const syncResponse = fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/sync`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
            },
          )
          alert(
            'Business sync initiated. The initial backfill will take ~90 seconds.',
          )
        } catch (error) {
          console.error('Error syncing business:', error)
          alert(`Failed to sync business: ${error}`)
        }
      }
      return (
        <button className='admin-button' onClick={handleSubmit}>
          Sync Business
        </button>
      )
    }

    return (
      <fieldset
        style={{
          padding: '20px',
          border: '2px solid black',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          marginTop: '20px',
        }}
      >
        <legend style={{ fontSize: '1.2em', padding: '0 10px' }}>
          Other Actions
        </legend>
        <ApproveMatchesButton />
        <SyncBusinessButton />
      </fieldset>
    )
  }

  if (loading) return <Loading />

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100vh',
        width: '100%',
        position: 'relative',
      }}
    >
      <h1>Import Transaction Data</h1>
      {defaultCustomAccount == null ? (
        <>
          No Custom Account found for business {businessId}
          <button
            onClick={async () => {
              const res = await getOrCreateDefaultCustomAccount(
                businessId,
                bearerToken,
              )
              setDefaultCustomAccount(res)
            }}
          >
            Get or create default custom account
          </button>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            gap: '20px',
            maxWidth: '90%',
          }}
        >
          <ImportInvoices
            demoName={demoName}
            businessId={businessId}
            bearerToken={bearerToken}
            invoicePayload={invoicePayload}
            setInvoicePayload={setExpensePayload}
            defaultCustomAccount={defaultCustomAccount}
          />
          <ImportBankTransactions
            businessId={businessId}
            bearerToken={bearerToken}
            expensePayload={expensePayload}
            setExpensePayload={setExpensePayload}
            defaultCustomAccount={defaultCustomAccount}
          />
        </div>
      )}
      <br />
      <OtherActions />
      <button
        className='admin-button'
        style={{
          width: '120px',
          position: 'absolute',
          bottom: '20px',
          left: '20px',
        }}
        onClick={() => setImportingTransactions(false)}
      >
        Return to demo
      </button>
    </div>
  )
}
