import React from 'react'

export const Loading = () => {
  const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    fontSize: '25px',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  }

  return <div style={centerStyle}>Loading...</div>
}
