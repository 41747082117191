import React from 'react'
import { AccountingLayout } from '../../../layouts/AccountingLayout'
import { ProjectProfitabilityView } from '@layerfi/components'
import { TagOption } from '@layerfi/components/views/ProjectProfitability/ProjectProfitability'

export const Projects = () => {
  const valueOptions: TagOption[] = [
    {
      label: 'All Projects',
      tagKey: 'project',
      tagValues: ['project-a', 'project-b'],
    },
    {
      label: 'Project A',
      tagKey: 'project',
      tagValues: ['project-a'],
    },
    {
      label: 'Project B',
      tagKey: 'project',
      tagValues: ['project-b'],
    },
  ]

  return (
    <AccountingLayout>
      <ProjectProfitabilityView valueOptions={valueOptions} showTitle={true} />
    </AccountingLayout>
  )
}
