import React, { Dispatch, SetStateAction, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { DemoControls } from '../DemoControls'
import { useSidebarContext } from '../../contexts/SidebarContext'
import AccountingIcon from '../../icons/Accounting'
import CoinsSwapIcon from '../../icons/CoinsSwap'
import FileIcon from '../../icons/File'

import './styles.css'

interface SidebarProps {
  demoName: string
  brandLogoUrl: string
  setImportingTransactions: Dispatch<SetStateAction<boolean>>
  isMobileView: boolean
  setIsMobileView: Dispatch<SetStateAction<boolean>>
  isBookkeepingMode: boolean
  setBookkeepingMode: Dispatch<SetStateAction<boolean>>
  showInvoicesTab: boolean
  showTooltips: boolean
  setShowTooltips: Dispatch<SetStateAction<boolean>>
}

export const Sidebar = ({
  demoName,
  brandLogoUrl,
  setImportingTransactions,
  isMobileView,
  setIsMobileView,
  setBookkeepingMode,
  isBookkeepingMode,
  showInvoicesTab,
  showTooltips,
  setShowTooltips,
}: SidebarProps) => {
  const { openSidebar } = useSidebarContext()
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <section className='sidebar'>
      <div
        className={`sidebar-bg ${isOpen ? 'sidebar-bg-open' : ''}`}
        onClick={toggleAccordion}
      />
      <div className='sidebar-content'>
        <div className='sidebar-header'>
          <div
            className={`switch-theme-wrapper ${
              isOpen ? 'switch-theme-wrapper-open' : ''
            }`}
          >
            <div className={'switch-theme-button'}>
              <img
                className='brand-logo'
                src={brandLogoUrl}
                alt='Client Logo'
              />
            </div>
            <div
              className={'switch-theme-content switch-theme-content-closed'}
            />
          </div>
        </div>
        <ul>
          <li>
            <NavLink
              to='.'
              className='nav-link active'
              onClick={() => openSidebar(false)}
            >
              <AccountingIcon />
              Accounting
            </NavLink>
          </li>
          <li>
            {showInvoicesTab ? (
              <NavLink
                to='./invoices'
                className='nav-link active'
                onClick={() => openSidebar(false)}
              >
                <AccountingIcon />
                Invoices
              </NavLink>
            ) : (
              <span className='nav-link disabled'>
                <FileIcon /> Invoices
              </span>
            )}
          </li>
          <li>
            <span className='nav-link disabled'>
              <CoinsSwapIcon /> Payouts
            </span>
          </li>
        </ul>
      </div>
      <button className='sidebar-close-btn' onClick={() => openSidebar(false)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 18 18'
          fill='none'
        >
          <path
            d='M13.5 4.5L4.5 13.5'
            stroke='black'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M4.5 4.5L13.5 13.5'
            stroke='black'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
      <DemoControls
        demoName={demoName}
        setImportingTransactions={setImportingTransactions}
        isMobileView={isMobileView}
        setIsMobileView={setIsMobileView}
        isBookkeepingMode={isBookkeepingMode}
        setBookkeepingMode={setBookkeepingMode}
        showTooltips={showTooltips}
        setShowTooltips={setShowTooltips}
      />
    </section>
  )
}
