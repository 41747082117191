import React from 'react'
import { BankTransactionsWithLinkedAccounts } from '@layerfi/components'
import { AccountingLayout } from '../../../layouts/AccountingLayout'
import { useOutletContext } from 'react-router-dom'

export const BankTransactions = () => {
  const {
    isMobileView,
    isBookkeepingMode,
    showDescriptions,
    showReceiptUploads,
    showTooltips,
  } = useOutletContext<{
    isMobileView: boolean
    isBookkeepingMode: boolean
    showDescriptions: boolean
    showReceiptUploads: boolean
    showTooltips: boolean
  }>()
  return (
    <AccountingLayout>
      <BankTransactionsWithLinkedAccounts
        showLedgerBalance={false}
        mode={isBookkeepingMode ? 'bookkeeping-client' : 'self-serve'}
        showDescriptions={showDescriptions}
        showReceiptUploads={showReceiptUploads}
        showTooltips={showTooltips}
        mobileComponent={isMobileView ? 'mobileList' : 'regularList'}
      />
    </AccountingLayout>
  )
}
