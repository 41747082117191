import { createContext, useContext, useState } from 'react'

export type SidebarContextType = ReturnType<typeof useSidebar>

export const SidebarContext = createContext<SidebarContextType>({
  isSidebarOpen: false,
  openSidebar: () => null,
})

export const useSidebarContext = () => {
  const context = useContext(SidebarContext)

  if (context == null) {
    throw new Error('Sidebar components must be wrapped in Sidebar Provider')
  }

  return context
}

export const useSidebar = () => {
  const [isSidebarOpen, openSidebar] = useState(false)

  return {
    isSidebarOpen,
    openSidebar,
  }
}
