import React from 'react'
import { Reports as ReportsView } from '@layerfi/components'
import { AccountingLayout } from '../../../layouts/AccountingLayout'
import { useOutletContext } from 'react-router-dom'
import { DateRangeDatePickerModes } from '../../Admin'
import { ProfitAndLossCompareOptionsProps } from '@layerfi/components/components/ProfitAndLossCompareOptions/ProfitAndLossCompareOptions'

export const Reports = () => {
  const { datePickerMode, useComparisonPnl } = useOutletContext<{
    datePickerMode?: DateRangeDatePickerModes
    useComparisonPnl: boolean
  }>()

  const nyTagFilter = {
    displayName: 'New York',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'location',
        tagValues: ['new york'],
      },
    },
  }

  const sfTagFilter = {
    displayName: 'San Francisco',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'location',
        tagValues: ['san francisco'],
      },
    },
  }

  const laTagFilter = {
    displayName: 'Los Angeles',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'location',
        tagValues: ['los angeles'],
      },
    },
  }

  const allLocationsTagFilter = {
    displayName: 'All Locations',
    tagFilterConfig: {
      structure: 'DEFAULT',
      tagFilters: {
        tagKey: 'location',
        tagValues: [],
      },
    },
  }

  const comparisonConfig: ProfitAndLossCompareOptionsProps | undefined =
    useComparisonPnl
      ? {
          tagComparisonOptions: [
            nyTagFilter,
            sfTagFilter,
            laTagFilter,
            allLocationsTagFilter,
          ],
          defaultTagFilter: allLocationsTagFilter,
        }
      : undefined

  return (
    <AccountingLayout>
      <ReportsView
        profitAndLossConfig={{
          datePickerMode: datePickerMode ?? undefined,
        }}
        comparisonConfig={comparisonConfig}
      />
    </AccountingLayout>
  )
}
