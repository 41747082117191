import * as React from 'react'
import { IconSvgProps } from './types'

const Accounting = ({ size = 14, ...props }: IconSvgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 14 14'
    fill='none'
    {...props}
    width={size}
    height={size}
  >
    <path
      d='M10.2426 3.50933L2.90926 10.8427M4.24259 6.176V3.50933M2.90926 4.84266H5.57593M7.57593 9.50933H10.2426M3.77593 13.176H9.37593C10.496 13.176 11.0561 13.176 11.4839 12.958C11.8602 12.7663 12.1662 12.4603 12.3579 12.084C12.5759 11.6562 12.5759 11.0961 12.5759 9.976V4.376C12.5759 3.25589 12.5759 2.69584 12.3579 2.26801C12.1662 1.89169 11.8602 1.58573 11.4839 1.39398C11.0561 1.17599 10.496 1.17599 9.37593 1.17599H3.77593C2.65582 1.17599 2.09577 1.17599 1.66795 1.39398C1.29162 1.58573 0.985661 1.89169 0.793915 2.26801C0.575928 2.69584 0.575928 3.25589 0.575928 4.37599V9.976C0.575928 11.0961 0.575928 11.6562 0.793915 12.084C0.985661 12.4603 1.29162 12.7663 1.66795 12.958C2.09577 13.176 2.65582 13.176 3.77593 13.176Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Accounting
